<template>
 <div class="main">

  <div class="row infos">
    <div class="col-xl-4 col-lg-12">
      <div class="blc-dados">
        <img src="/img/icon-fields.png" alt="icone porto">
        <p>List {{title}}</p>
      </div>
    </div>
  </div>

   <div class="fundo">
    <div class="blc-inf-geral">
      <div class="blc-item">
        <div class="row align-items-center">
          <div class="col">
            <h2 >
              List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
            </h2>
          </div>
          <div class="col text-right">
             <!-- <button class="submit-verde"  @click="cleanFiltros()">Clear Filters <img src="/img/icon-filter.png"></button> -->
            <button class="submit"  @click="openModalForm(null)">New +</button>
        
        </div>
      </div>
      <!-- pesquisa -->
      <form>
      <div class="row align-items-center mt-3">
        <div class="col-lg-5 mrg-top-30" >
          <base-input
          alternative=""
          label="Name"
          placeholder="search by name"
          input-classes="form-control-alternative"
          v-model.lazy="search.name"
          />
        </div>
        <div class=" mrg-top-30 col-lg-5">
          <base-input
          alternative=""
          label="Country"
          placeholder="search by country"
          input-classes="form-control-alternative"
          v-model.lazy="search.pais"
          v-if="type == 'lineup_porto'"
          />
        </div>

        <div class="col-lg-2 mrg-top-30" >
         <button style="margin-top: 15px;" class="submit w-100" value="Search"   :disabled="loading"
          @click="listar()"> <i class="fa fa-spinner fa-spin" v-if="loading" /> Search </button>
          <!-- <button type="submit" class="btn base-button btn-primary w-100" @click="submit()"><i class="fa fa-check"></i></button> -->
        </div>
      </div>
      </form>
      <!-- pesquisa -->
    </div>

    <div class="blc-tabela tabela-listagem scroll" style="overflow: visible">
      <table
   
      >
      <thead >
        <tr>
          <th>Name</th>
          <th v-if="type == 'navios'">IMO</th>
          <th v-if="type == 'lineup_porto'">Country</th>
          <th></th>
        </tr>
      </thead>

      <tbody >
        <tr v-for="(row, index) in list" :key="list.id">
          <td>
            {{ row.name }}
          </td>
          <td v-if="type == 'navios'">
            {{ row.imo }}
          </td>
          <td v-if="type == 'lineup_porto'">
            {{ row.pais }}
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
            <a
            slot="title"
            class="btn btn-sm btn-icon-only "
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >
            <i class="fas fa-ellipsis-v"></i>
          </a>

          <template>
            <a class="dropdown-item" @click="openModalForm(row.id)"
            ><i class="fas fa-user-edit"></i> edit</a
            >
            <a
            class="dropdown-item"
            href="#"
            @click="
            $root.openExcluirModal(
              row,
              'Would you like to delete?',
              Service
              )
              "
              ><i class="fas fa-trash" style="min-width: 20px"></i>
              delete</a
              >
            </template>
          </base-dropdown>
        </td>
      </tr>
      <tr v-if="!list.length && !loading">
        <td colspan="100" style="text-align: center">No news found</td>
      </tr>
      <tr v-if="loading">
        <td colspan="100" style="text-align: center">
          <i
          class="fa fa-spinner fa-spin"
          v-if="loading"
          style="font-size: 20px"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>


</div>
</div>
<excluir-modal
:show="$root.modal.excluir.isOpen"
:mensagem="$root.modal.excluir.mensagem"
:model="$root.modal.excluir.model"
:service="$root.modal.excluir.service"
@close="handleExcluir"
></excluir-modal>
<modal
:show="modal.form.show"
@close="
() => {
  modal.form.show = false;
}
"
>
<template v-slot:header> Add new </template>
<template>
  <generic-form
  v-if="modal.form.show"
  :id="modal.form.id"
  :service="Service"
  :type="type"
  @success="handleModalForm"
  />
</template>
</modal>
<base-pagination
:pagination="pagination"
@change="listar()"
align="center"
></base-pagination>
</div>
</template>
<script>
  import NaviosService from "@/services/Navios";
  import AgenciasService from "@/services/Agencias";
  import TrabalhadoresService from "@/services/Trabalhadores";
  import LineupsPortosService from "@/services/LineupsPortos";
  import AfretadoresService from "@/services/Afretadores";
  import GenericForm from "./Form.vue";
  export default {
    components: {
      "generic-form": GenericForm,
    },
    data() {
      return {
        title:'',
        Service: {},
        type: "",
        cargas: [],
        modal: {
          form: {
            show: false,
            id: null,
          },
        },
        loading: false,
        pagination: {
          page: 1,
          last_page: 1,
          per_page: 20,
          total: 1,
        },
        search: {
          name: "",
        },
        orderBy: {
          campo: "name",
          ordem: "asc",
        },
        list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
        ],
      };
    },
    created() {
      this.setGenericField();
    },

    watch: {
    // will fire on route changes
    //'$route.params.id': function(val, oldVal){ // Same
      "$route.path": function (val, oldVal) {
        this.setGenericField();
      },
    },
    methods: {
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.listar();
      },
      openModalForm(id) {
        this.modal.form.id = id;
        this.modal.form.show = true;
      },
      handleModalForm(model) {
        this.listar();
        this.modal.form.show = false;
      },
      setGenericField() {
        let campo = window.location.pathname.split("/").pop();
        this.type = campo;
        switch (campo) {
          case "navios":
          default:
          this.title = 'Vessels'
          this.Service = NaviosService;
          break;
          case "afretadores":
          this.title = 'Charterers'
          this.Service = AfretadoresService;
          break;
          case "trabalhadores":
          this.Service = TrabalhadoresService;
          this.title = 'Charterers'
          break;
          case "agencias":
          this.Service = AgenciasService;
          this.title = 'Agencies'
          break;
          case "lineup_porto":
          this.Service = LineupsPortosService;
          this.title = 'Ports of destination'
          break;
        }
      // if(this.type=='lineup_porto'){
      //   this.orderBy.campo = 'pais'
      // }else{
      //   this.orderBy.campo = 'name'
      // }
      this.listar();
      console.log(campo);
    },
    listar() {
      this.loading = true;
      this.list = [];
      this.Service.get({
        ...this.pagination,
        ...this.search,
        ...this.orderBy,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style></style>
