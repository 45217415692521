<template>
  <div class="card">
    <div class="card-header">
      <h2>Edit profile</h2>
    </div>
    <div class="card-body">
      <form ref="profile_form" @submit.prevent="handleProfileUpdate">
        <label class="form-control-label">Name</label>
        <base-input
        alternative=""
        placeholder="Your name"
        v-model="user.name"
        class="input-group-alternative"
        />
        <validation-error :errors="apiValidationErrors.name" />

        <label class="form-control-label">Email</label>
        <base-input
        placeholder="Email"
        v-model="user.email"
        class="input-group-alternative"
        />
        <validation-error :errors="apiValidationErrors.email" />

                    <label class="w-100">Avatar</label>
        <div class="input-group mb-2">

          <div class="custom-file">

            <input
            type="file"
            class="custom-file-input input"
            id="photo"
            accept="image/*"
            @change="changePhoto"
            />
            <label class="custom-file-label" for="photo" style="border-radius: 100px" 
            >Choose file</label
            >
          </div>
        </div>

        <div class="my-4">
          <base-button type="button" class="submit" native-type="submit">
          Save
        </base-button>
      </div>
    </form>
  </div>
</div>
</template>
<script>
  import BaseInput from "@/components/BaseInput.vue";
  import BaseButton from "@/components/BaseButton.vue";
  import formMixin from "@/mixins/form-mixin";
  import ValidationError from "@/components/ValidationError.vue";

  export default {
    name: "UserEditCard",

    components: {
      BaseInput,
      BaseButton,
      ValidationError,
    },

    mixins: [formMixin],

    props: {
      user: Object,
    },

    methods: {
      changePhoto(event) {
      this.user.photo = event.target.files[0];
    },
      async handleProfileUpdate() {
        let user = this.user;
        user.password = "";
        user.confirm_password = "";
        try {
          
          await this.$store.dispatch("profile/update", this.$helper.getFormDataTrue(user));

          this.resetApiValidation();
          this.$notify({
            type: "success",
            message: "Profile saved successfully.",
          });

          await this.$store.getters["profile/me"];
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Please check the form!",
          });
          console.log(error.response.data.errors);
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
