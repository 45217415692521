<template>
   <div  class="main">
    <div class="row infos">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-grups.png" alt="icone barco">
          <p> Activity</p>
        </div>
      </div>
    </div>
     <div class="fundo">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                Update information
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
          </div>
        </div>

        <div class="table-responsive" style="overflow: visible">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes="list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th>Field</th>
                <th>Before</th>
                <th>After</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="key in Object.keys(log.new_model)">
                <tr v-if="log.old_model[key] || log.new_model[key]">
                  <td translate="yes">{{ key }}</td>
                  <td>{{ log.old_model[key] }}</td>
                  <td>{{ log.new_model[key] }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
  <div class="col-12 btn-final txt-right">
    <input type="submit" class="submit-branco" value="See listing" @click="$router.push('/usuarios/atividades')">

    
  </div>
</div>
  </div>
</template>
<script>
import Service from "@/services/UserLogs";
export default {
  data: () => ({
    Service,
    log: {},
  }),
  created() {
    this.fetchLog();
  },
  methods: {
    async fetchLog() {
      try {
        const { data } = await Service.find(this.$route.params.id);
        this.log = data;
      } catch ({ response }) {
        if (response.status === 404)
          this.$router.push({ name: "Atividades usuários" });
      }
    },
  },
};
</script>
