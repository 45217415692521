import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import UserProfile from "@/views/Usuarios/UserProfile.vue";
import ListarUsuarios from "@/views/Usuarios/Listar.vue";
import AtividadesUsuarios from "@/views/Usuarios/Logs/List.vue";
import MostrarAtividadeUsuario from "@/views/Usuarios/Logs/Show.vue";
import FormUsuarios from "@/views/Usuarios/Form.vue";
import ListarPortos from "@/views/Portos/Listar.vue";
import FormPortos from "@/views/Portos/Form.vue";
import ListarGeneric from "@/views/Generic/Listar.vue";
import ListarCargasTipos from "@/views/CargasTipos/Listar.vue";
import FormCargasTipos from "@/views/CargasTipos/Form.vue";
import ListarLineups from "@/views/Lineups/Listar.vue";
import FormLineups from "@/views/Lineups/Form.vue";
import FormEmail from "@/views/Email/Form.vue";
import TestInputDate from "@/views/TestInputDate.vue";

const PasswordReset = () => import("@/views/Password/Reset.vue");
const PasswordEmail = () => import("@/views/Password/Email.vue");
Vue.use(Router);

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import superAdmin from "@/middleware/superAdmin";
import operator from "@/middleware/operator";
import operatorNotSub from "@/middleware/operatorNotSub";

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: DashboardLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("./views/Dashboard.vue"),
          meta: { middleware: auth },
        },
        {
          path: "/perfil",
          name: "Perfil",
          component: UserProfile,
          meta: { middleware: auth },
        },
      ],
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("./views/Login.vue"),
          meta: { middleware: guest },
        },
        {
          path: "/register",
          name: "register",
          component: () => import("./views/Register.vue"),
          meta: { middleware: guest },
        },
        {
          path: "/password/reset",
          name: "PasswordReset",
          component: PasswordReset,
          meta: { middleware: guest },
        },
        {
          path: "/password/email",
          name: "PasswordEmail",
          component: PasswordEmail,
          meta: { middleware: guest },
        },
      ],
    },
    {
      path: "/cargas_tipos",
      component: DashboardLayout,
      name: "Tipos de cargas",
      children: [
        {
          path: "adicionar",
          name: "Adicionar carga",
          components: { default: FormCargasTipos },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar carga",
          components: { default: FormCargasTipos },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar tipos de carga",
          components: { default: ListarCargasTipos },
          meta: { middleware: admin },
        },
      ],
    },
    {
      path: "/lineups",
      component: DashboardLayout,
      name: "Lineups",
      children: [
        {
          path: "adicionar",
          name: "Adicionar lineup",
          components: { default: FormLineups },
          meta: { middleware: operator },
        },
        {
          path: "editar/:id",
          name: "Editar lineup",
          components: { default: FormLineups },
          meta: { middleware: operator },
        },
        {
          path: "listar",
          name: "Listar lineups",
          components: { default: ListarLineups },
          meta: { middleware: auth },
        },
      ],
    },
    {
      path: "/portos",
      component: DashboardLayout,
      name: "Portos",
      children: [
        {
          path: "adicionar",
          name: "Adicionar porto",
          components: { default: FormPortos },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar porto",
          components: { default: FormPortos },
          meta: { middleware: operatorNotSub },
        },
        {
          path: "listar",
          name: "Listar portos",
          components: { default: ListarPortos },
          meta: { middleware: operatorNotSub },
        },
      ],
    },
    {
      path: "/usuarios",
      component: DashboardLayout,
      name: "Usuários",
      children: [
        {
          path: "adicionar",
          name: "Adicionar usuário",
          components: { default: FormUsuarios },
          meta: { middleware: superAdmin },
        },
        {
          path: "editar/:id",
          name: "Editar usuário",
          components: { default: FormUsuarios },
          meta: { middleware: superAdmin },
        },
        {
          path: "listar",
          name: "Listar usuários",
          components: { default: ListarUsuarios },
          meta: { middleware: superAdmin },
        },
        {
          path: "atividades",
          name: "Atividades usuários",
          components: { default: AtividadesUsuarios },
          meta: { middleware: superAdmin },
        },
        {
          path: "atividades/:id",
          name: "Mostrar atividades usuário",
          components: { default: MostrarAtividadeUsuario },
          meta: { middleware: superAdmin },
        },
      ],
    },
    {
      path: "/campos",
      component: DashboardLayout,
      name: "Campos da lineup",
      children: [
        {
          path: "navios",
          name: "Navios",
          components: { default: ListarGeneric },
          meta: { middleware: admin },
          force: true,
        },
        {
          path: "agencias",
          name: "Agências",
          components: { default: ListarGeneric },
          meta: { middleware: admin },
        },
        {
          path: "afretadores",
          name: "Afretadores",
          components: { default: ListarGeneric },
          meta: { middleware: admin },
        },
        {
          path: "lineup_porto",
          name: "Porto Lineup",
          components: { default: ListarGeneric },
          meta: { middleware: admin },
        },
      ],
    },
    {
      path: "/email_information",
      component: DashboardLayout,
      name: "Email Information",
      children: [
        {
          path: "",
          name: "Email Information",
          components: { default: FormEmail },
          meta: { middleware: admin },
        },
      ],
    },

    {
      path: "/inputdate",
      component: DashboardLayout,
      name: "inputdate",
      children: [
        {
          path: "test",
          name: "Adicionar lineup",
          components: { default: TestInputDate },
          meta: { middleware: admin },
        },
       
      ],
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
