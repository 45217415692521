var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  {'input-group': _vm.hasIcon},
  {'form-group': _vm.label},
  // {'has-danger': errors.length},
  {'focused': _vm.focused},
  {'has-label': _vm.label || _vm.$slots.label} ]},[_vm._t("label",[(_vm.label)?_c('label',{staticClass:" w-100",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend",on:{"click":function($event){return _vm.$emit('btn')}}},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",[_c('i',{class:_vm.addonLeftIcon})])],2)]):_vm._e(),_vm._t("default",[_c('select',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[
    {'is-valid': _vm.valid === true},
    {'is-invalid': _vm.valid === false}, _vm.inputClasses],attrs:{"aria-describedby":"addon-right addon-left"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.model=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},'select',_vm.$attrs,false),_vm.listeners),[(_vm.placeholder)?_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_vm._l((_vm.options),function(option){return _c('option',{domProps:{"value":option[_vm.optionsKey]}},[_vm._v(_vm._s(option[_vm.optionsValue]))])})],2)],null,_vm.slotData),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",[_c('i',{class:_vm.addonRightIcon})])],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",_vm._l((_vm.errors),function(erro){return (_vm.errors)?_c('div',{staticClass:"text-danger invalid-feedback",class:{'mt-2': _vm.hasIcon},staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()}))],2)}
var staticRenderFns = []

export { render, staticRenderFns }