<template>
  <div>
    <dashboard-navbar></dashboard-navbar>
    <section id="espaco"></section>

    <section id="conteudo">
      <div class="container-fluid">
        <div class="row">
          <div class="menu-lateral desktop transition_on">
            <div class="item-menu user">
              <img
              v-if="user.photo_url"
              :src="user.photo_url"
              alt="Foto Usuário"
              />
              <p>
                {{ user.name }}<br /><em>{{ user.role }}</em>
              </p>
            </div>

            <router-link to="/dashboard">
              <div class="item-menu">
                <img src="/img/icon-statistics.png" alt="Statistics" />
                <p>Statistics</p>
              </div>
            </router-link>

            <div
            class="item-menu subitens"
            v-if="$root.isAdmin || $root.isBasicAdmin"
            @click="toggleMenu"
            >
            <img src="/img/icon-comm.png" alt="Comm" />
            <p>Comm</p>
            <span>></span>

            <ul >
              <router-link to="/cargas_tipos/adicionar">
                <li>New</li>
              </router-link>
              <router-link to="/cargas_tipos/listar">
                <li>List</li>
              </router-link>
            </ul>
          </div>

          <div class="item-menu subitens" @click="toggleMenu">
            <img src="/img/icon-lines.png" alt="Line-ups" />
            <p>Line-ups</p>
            <span>></span>

            <ul @click="hideMenu()">
              <router-link to="/lineups/adicionar" v-if="!$root.isClient">
                <li>New</li>
              </router-link>
              <router-link to="/lineups/listar">
                <li>List</li>
              </router-link>
            </ul>
          </div>

          <div
          class="item-menu subitens"
          v-if="$root.isAdmin || $root.isBasicAdmin"
          @click="toggleMenu"
          >
          <img src="/img/icon-fields.png" alt="Fields of line-ups" />
          <p>Fields of line-ups</p>
          <span>></span>

          <ul>
            <router-link to="/campos/afretadores">
              <li>Charterers</li>
            </router-link>

            <router-link to="/campos/agencias">
              <li>Agencies</li>
            </router-link>

            <router-link to="/campos/navios">
              <li>Vessels</li>
            </router-link>

            <router-link to="/campos/lineup_porto">
              <li>Ports of destination</li>
            </router-link>
          </ul>
        </div>

        <div
        class="item-menu subitens"
        v-if="$root.isAdmin || $root.isBasicAdmin || $root.isOperator"
        @click="toggleMenu"
        >
        <img src="/img/icon-ports.png" alt="Ports" />
        <p>Ports</p>
        <span>></span>

        <ul>
          <router-link to="/portos/adicionar"  v-if="$root.isAdmin || $root.isBasicAdmin" >
            <li>New</li>
          </router-link>
          <router-link to="/portos/listar">
            <li>List</li>
          </router-link>
        </ul>
      </div>

      <div
      class="item-menu subitens"
      v-if="$root.isAdmin"
      @click="toggleMenu"
      >
      <img src="/img/icon-grups.png" alt="Users" />
      <p>Users</p>
      <span>></span>

      <ul>
        <router-link to="/usuarios/adicionar">
          <li>New</li>
        </router-link>
        <router-link to="/usuarios/listar">
          <li>List</li>
        </router-link>
        <router-link to="/usuarios/atividades">
          <li>Activities</li>
        </router-link>
      </ul>
    </div>
    <router-link
    class="item-menu subitens"
    to="/email_information"
    v-if="$root.isAdmin || $root.isBasicAdmin"
    >
    <img src="/img/icon-comm.png" alt="Comm" />
    <p>Email Information</p>
  </router-link>
</div>

<fade-transition :duration="200" origin="center top" mode="out-in">
  <!-- your content here -->
  <router-view></router-view>
</fade-transition>
</div>
</div>
</section>
</div>
</template>
<script>
  import DashboardNavbar from "./DashboardNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import { FadeTransition } from "vue2-transitions";

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
    },
    data() {
      return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      user: {},
    };
  },
  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.user = me;
      }
      );
  },

  methods: {
    toggleMenu(event) {
      let menu = event.target;
      const menus = document.querySelectorAll(".item-menu.subitens");
      while (!menu.classList.contains("subitens")) {
        menu = menu.parentNode;
      }
      if (menu.classList.contains("ativo")) {
        menus.forEach((m) => m.classList.remove("ativo"));
      } else {
        menus.forEach((m) => m.classList.remove("ativo"));
        menu.classList.add("ativo");
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },

  mounted() {
    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
          ) {
          // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
        ? target
        : $("[name=" + this.hash.slice(1) + "]");
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                  // Checking if the target was focused
                  return false;
                } else {
                  $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              }
              );
          }
        }
      });

    //----- Menu

    // $(".hamburguer-bt, .fundo_menu").click(function () {
    //   if ($(".hamburguer-bt").hasClass("ativo")) {
    //     $(".hamburguer-bt").removeClass("ativo");
    //     $(".fundo_menu").removeClass("ativo");
    //     // $("#opt_menu").removeClass("ativo");
    //   } else {
    //     $(".hamburguer-bt").addClass("ativo");
    //     $(".fundo_menu").addClass("ativo");
    //     // $("#opt_menu").addClass("ativo");
    //   }
    // });

    // $("#opt_menu li a").click(function () {
    //   $(".hamburguer-bt").removeClass("ativo");
    //   $(".fundo_menu").removeClass("ativo");
    //   $("#opt_menu").removeClass("ativo");
    // });

    var window_top = $(window).scrollTop() + 1;
    if (window_top > 35) {
      $("header").addClass("drop");
      $("#up").css({ opacity: "1" });
    } else {
      $("header").removeClass("drop");
      $("#up").css({ opacity: "0" });
    }
    $(window).scroll(function () {
      var window_top = $(window).scrollTop() + 1;
      if (window_top > 35) {
        $("header").addClass("drop");
        $("#up").css({ opacity: "1" });
      } else {
        $("header").removeClass("drop");
        $("#up").css({ opacity: "0" });
      }
    });

    $(".blc-segundo .txt , #opt_menu .menu-lateral .item-menu .txt").click(
      function () {
        $(
          ".blc-segundo .txt .sub , #opt_menu .menu-lateral .item-menu .txt .sub"
          ).slideToggle();
      }
      );
    //----- Menu lateral

    // $(".item-menu.subitens").click(function () {
    //   if ($(this).hasClass("ativo")) {
    //     $(".item-menu.subitens").removeClass("ativo");
    //   } else {
    //     $(".item-menu.subitens").removeClass("ativo");
    //     $(this).addClass("ativo");
    //   }
    // });

    //----- Blocos Selecteds

    // $(".blc-selected a").click(function(){

    //   if( $(this).hasClass("ativo") ){
    //     $(this).removeClass('ativo');
    //   }else{
    //     $(this).addClass('ativo');
    //   }
    // });

    //----- Contador

    if ($.fn.counterUp) {
      $(".countercd").counterUp({
        delay: 10,
        time: 2000,
      });
    }
  },
};
</script>
<style lang="scss"></style>
