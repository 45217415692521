<template>
  <div class=" bg-default">
    <!-- Navbar -->

    <!-- Header -->
    <div class="header pt-5">
      <div class="container">
        <div class="header-body text-center mb-6">
          <div class="text-center">
            <div>
              <h1 class="text-white">
                <router-link to="/login">
                <img src="/img/logo-site.png" style="filter: brightness(0) invert(1);" height="200" width="350" />
                </router-link>
              </h1>
            </div>

          
          </div>
        </div>
      </div>

    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
  import { SlideYUpTransition } from "vue2-transitions";

  export default {
    name: "auth-layout",
    components: {
      SlideYUpTransition,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false,
      };
    },
    computed: {
      isLogin() {
        return this.$route.name === "login";
      },
      isRegister() {
        return this.$route.name === "register";
      },
      isPassword() {
        return this.$route.name === "PasswordReset";
      },
    },
  };
</script>
<style>

body{
  background: #18191D!important; 
}
</style>
