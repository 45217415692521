<template>
  <div
    style="position: relative"
    :class="[
      // { 'input-group': hasIcon },
      // { 'form-group' : formGroup},
      // {'has-danger': errors.length},
      { focused: focused },
      // { 'has-label': label || $slots.label },
    ]"
  >
    <slot name="label">
      <label
        v-if="label"
        class="form-control-label w-100"
        :class="labelClasses"
      >
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <!-- <div v-if="addonLeftIcon " class="input-group-prepend" @click="$emit('btn')">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div> -->
    <slot v-bind="slotData">
      <label
        for="vessel"
        class="multiplo"
        v-if="addonLeftIcon"
        @click="$emit('btn')"
        >+</label
      >
      <multiselect
        v-model="model"
        :options="options"
        :track-by="optionsKey"
        :label="optionsValue"
        v-on="listeners"
        v-bind="$attrs"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :deselectLabel="deselectLabel"
        :placeholder="placeholder"
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          { multiplo: addonLeftIcon },
          inputClasses,
        ]"
      >
        <template slot="beforeList">
          <template>
            <li
              class=""
              v-if="
                options.length > 1 &&
                Array.isArray(model) &&
                model.length != options.length
              "
              @click="addAll()"
            >
              <span data-deselect="Remove" class="multiselect__option btn-info"
                ><span>ADD ALL OPTIONS</span></span
              >
            </li>
            <li
              class=""
              v-if="
                Array.isArray(model) && model.length > 1 && options.length > 0
              "
              @click="removeAll()"
            >
              <span
                data-deselect="Remove"
                class="multiselect__option btn-danger"
                ><span>REMOVE ALL OPTIONS</span></span
              >
            </li>
          </template>
        </template>
        <template slot="noOptions">No results found</template>
        <template slot="noResult">No results found</template>
      </multiselect>
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <!--   <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
      {{ error }}
    </div> -->
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-for="erro in errors"
        v-if="errors"
      >
        {{ erro }}
      </div>
    </slot>
  </div>
</template>
<script>
import { ref } from "vue";
import Multiselect from "vue-multiselect";
export default {
  inheritAttrs: false,
  name: "multiple-select",
  components: { Multiselect },
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    // valid: {
    //   type: Boolean,
    //   description: "Whether is valid",
    //   default: undefined
    // },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    placeholder: {
      type: String,
      default: null,
      description: "default option",
    },
    selectLabel: {
      default: "Select",
    },
    selectedLabel: {
      default: "Selected",
    },
    deselectLabel: {
      default: "Remove",
    },
    formGroup: {
      default: true,
    },
    // error: {
    //   type: String,
    //   description: "Input error (below input)"
    // },
    errors: {
      type: Array,
      default: () => [],
      description: "Input error (below input)",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
      default: "input",
    },
    value: {
      type: [String, Number, Array],
      description: "Input value",
    },
    options: {
      type: Array,
      description: "options value",
    },
    optionsKey: {
      type: String,
      default: "id",
      description: "key name",
    },
    optionsValue: {
      type: String,
      default: "name",
      description: "value name",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },
  },

  data() {
    return {
      model: [],
      focused: false,
    };
  },
  computed: {
    valid() {
      return this.errors.length;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  watch: {
    options: {
      // the callback will be called immediately after the start of the observation
      immediate: false,
      handler(val, oldVal) {
        this.init();
      },
    },
    value: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      // console.log('init',this.value);
      if (this.value) {
        if (Array.isArray(this.value)) {
          return (this.model = this.options.filter((opt) =>
            this.value.includes(opt[this.optionsKey])
          ));
        }
        return (this.model = this.options.find(
          (opt) => this.value == opt[this.optionsKey]
        ));
      }else{
        this.model = null;
      }
    },
    addAll() {
      this.model = [...this.options];
      this.updateValue(this.model);
    },
    removeAll() {
      this.model = [];
      this.updateValue(this.model);
    },
    updateValue(evt) {
      // console.log('updated');
      // console.log(evt, evt[this.optionsKey]);
      let value;
      if (Array.isArray(this.value)) {
        value = evt.map((values) => values[this.optionsKey]);
      } else {
        value = evt[this.optionsKey];
      }
      this.model = evt;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  max-height: 39px;
  /* overflow-x: scroll; */
  overflow: scroll;
  height: calc(1.5em + 1.25rem + 5px);
  margin: 1px 33px 0px 20px;
  padding: 8px 0 0 0;
  font-family: var(--font-montserrat);
  font-weight: var(--font-light);
  border: none;
  font-size: 12px;
}

.input-group .form-control:not(:first-child) {
  /* border-left: 0; */
  /* padding-left: 0; */
  /* margin: 0; */
  padding: 0;
}

.multiselect__placeholder {
  color: inherit;
}
.input-group .form-control:not(:first-child) .multiselect__tags {
  border-left: 0;
  padding-left: 0;
  /* margin: 0; */
  height: calc(1.5em + 1.25rem + 5px);
  padding: 11px 40px 0 8px;
  /*padding: 0;*/
}
.multiselect {
  padding: 0;
}

.multiselect__input {
  font-size: inherit;
}

.multiselect__option {
  font-size: inherit;
  font-weight: 500
}
.multiselect__option,
.multiselect__option--highlight,
.multiselect__option--selected {
  font-size: 12px;
}

.input-group .form-control:not(:first-child) .multiselect__select {
  padding: 12px 8px 0 8px;
  /*padding: 0;*/
}
.multiselect__single {
  color: #8898aa;
}

.multiselect__tags::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.multiselect__tag {
  background: var(--cor-cinza);
}
.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: var(--cor-cinza-escuro);
}

.multiselect__option--highlight {
  background: var(--cor-cinza);
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: var(--cor-cinza);
  color: #fff;
}

label.multiplo {
  z-index: 2;
  position: absolute;
  /*margin: 35px 0px 12px 1px;*/
  width: 42px;
  min-height: 42px;
  background: var(--cor-azul-escuro);
  color: var(--cor-branco);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 23px !important;
  font-weight: 400 !important;
  padding-bottom: 3px;
}

.input.multiplo .multiselect__tags {
  margin: 1px 33px 0px 40px;
}

.input.multiplo {
  padding: 0px 0px 0px 0px;
}

.input.multiplo .multiselect__placeholder {
  padding-left: 7px;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: transparent;
  color: #a6a6a6;
}
.multiselect--above .multiselect__content-wrapper {
    bottom: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
}
</style>
