import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = "/portos";
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Portos {
  static get(params, getUserPortos = false) {
    if (getUserPortos) {
      const meAbstract = new Abstract(
        process.env.VUE_APP_API_BASE_URL + "/me/portos"
      );
      return meAbstract.get({ ...params });
    }
    return abstract.get({ ...params });
  }

  static find(id, params) {
    return abstract.find(id, params);
  }

  static update(model) {
    return axios.post(
      url + "/" + model.id,
      helper.getFormData({ ...model, _method: "put" })
    );
  }

  static create(model) {
    return axios.post(url, helper.getFormData(model));
  }

  static submit(model) {
    return abstract.submit(model, false); // qnd true é passado é para ser convertido em formData
  }

  static atualizar(model, carga, atualizar) {
    return axios.post(url + "/" + model.id + "/atualizar", {
      carga: carga,
      atualizar: atualizar,
    });
  }
  static delete(id) {
    return abstract.delete(id);
  }
}
