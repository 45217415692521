
export const helper = {
  estados:{ 
    'AC': 'Acre',
    'AL': 'Alagoas',
    'AP': 'Amapá',
    'AM': 'Amazonas',
    'BA': 'Bahia',
    'CE': 'Ceará',
    'DF': 'Distrito Federal',
    'ES': 'Espírito Santo',
    'GO': 'Goiás',
    'MA': 'Maranhão',
    'MT': 'Mato Grosso',
    'MS': 'Mato Grosso do Sul',
    'MG': 'Minas Gerais',
    'PA': 'Pará',
    'PB': 'Paraíba',
    'PR': 'Paraná',
    'PE': 'Pernambuco',
    'PI': 'Piauí',
    'RJ': 'Rio de Janeiro',
    'RN': 'Rio Grande do Norte',
    'RS': 'Rio Grande do Sul',
    'RO': 'Rondônia',
    'RR': 'Roraima',
    'SC': 'Santa Catarina',
    'SP': 'São Paulo',
    'SE': 'Sergipe',
    'TO': 'Tocantins'
  },
  getFormDataold: (model,form,namespace ='') => {
		// let formData = new FormData();
		// for ( var key in data ) {
		// 	if(data[key])
		// 		formData.append(key, data[key]);
		// }
		// return formData;
		let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          helper.getFormDataold(element, formData, tempFormKey);
        });
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
        helper.getFormDataold(model[propertyName], formData, formKey);
      else

        formData.append(formKey, model[propertyName]);
    }
    return formData;
  },

  getFormDataTrue: (model,form,namespace ='') => {
    // let formData = new FormData();
    // for ( var key in data ) {
    //  if(data[key])
    //    formData.append(key, data[key]);
    // }
    // return formData;
    let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
          // console.log()
           // if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
           let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
           if (model[propertyName] instanceof Date)
            formData.append(formKey, model[propertyName].toISOString());
          else if (model[propertyName] instanceof Array) {
            if(typeof model[propertyName][0] === 'object'){
              model[propertyName].forEach((element, index) => {
                const tempFormKey = `${formKey}[${index}]`;
                helper.getFormDataTrue(element, formData, tempFormKey);
              });
            }else{
             model[propertyName].forEach((element, index) => {
              const tempFormKey = `${formKey}[${index}]`;
              console.log( tempFormKey,propertyName,element);
              formData.append(tempFormKey, element);
            });

                // formData.append(formKey, model[propertyName]);
              }
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
              helper.getFormDataTrue(model[propertyName], formData, formKey);
            else

              formData.append(formKey, model[propertyName]);
          }
          return formData;
        },

        set(obj1, obj2, keys){    
          keys.forEach(field => {
            if(obj2.hasOwnProperty(field))
              obj1[field] = obj2[field]            
          });
        }

      }