import store from "../store";

export default function auth({ next, router }) {
  if (
    !store.getters.isAuthenticated ||
    !(store.getters.isAdmin || store.getters.isBasicAdmin)
  ) {
    return router.push({ name: "login" });
  }

  return next();
}
