<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <base-input-date
                    inputClasses="input"
                    data-no-dragscroll
                    
                    placeholder="TBI"
                    
                    v-model="eta"
                  />
                </div>
                
            </div>
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <base-input-date
                    inputClasses="input"
                    data-no-dragscroll
                    
                    placeholder="TBI"
                    
                    v-model="etb"
                  />
                </div>
                
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <projects-table title="Light Table"></projects-table>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <projects-table type="dark" title="Dark Table"></projects-table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  
  export default {
    name: 'tables',
   data() {
    return {
     eta:null,
     etb:'2023-05-05'
    };
  },
  };
</script>
<style></style>
