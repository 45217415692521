import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      // Accept: "application/vnd.api+json",
      // "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/me`, options).then(response => {
    return {
      list: response.data,
      meta: response.data
    };
  });
}

function update(profile) {
  // const payload = jsona.serialize({
  //   stuff: profile,
  //   includeNames: []
  // });

  const options = {
    headers: {
      // Accept: "application/vnd.api+json",
      // "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/me`, profile, options)
    .then(response => {
      return response.data;
    });
}

export default {
  get,
  update
};
