<template>
  <div class="card">
    <div class="card-header">
      <h2>Change password</h2>
    </div>
    <div class="card-body">
      <form ref="password_form" @submit.prevent="handleChangePassword">
        <base-input
          v-model="password"
          type="password"
          name="new_password"
          autocomplete="on"
          class="input-group-alternative"
          label="Password"
          placeholder="New password"
        />
        <validation-error :errors="errors.password" />
        <base-input
          v-model="password_confirmation"
          type="password"
          name="confirm_password"
          label="Confirm password"
          autocomplete="on"
          class="input-group-alternative"
          placeholder="Confirm password"
        />
        <validation-error :errors="errors.password_confirmation" />
        <div class="my-4">
          <base-button type="button" class="submit" native-type="submit">
            Save
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "UserPasswordCard",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
      errors: {},
      password: null,
      password_confirmation: null,
    };
  },

  methods: {
    async handleChangePassword() {
      if (!this.password || !this.password_confirmation) {
        this.$notify({
          type: "danger",
          message: "Preencha todos os campos!",
        });
        return false;
      }

      this.user.password = this.password;
      this.user.password_confirmation = this.password_confirmation;

      try {
        await this.$store.dispatch("profile/update", this.user);
        this.$refs["password_form"].reset();

        // this.resetApiValidation();
        this.errors = {};
        this.$notify({
          type: "success",
          message: "Senha alterada!.",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Confira os dados!",
        });
        this.errors = error.response.data.errors;
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
