<template>
  <div class="main">
    <div class="row infos">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-grups.png" alt="icone barco" />
          <p v-html="model.id ? 'New user' : 'Edit user'"></p>
        </div>
      </div>
    </div>

    <div class="fundo">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>User</h2>
          <div class="row">
            <div class="col-xl-12 order-xl-1">
              <form @submit.prevent>
                <h3>Information of user</h3>

                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      required
                      label="Name"
                      placeholder="user name"
                      input-classes="form-control-alternative"
                      :errors="errors.name"
                      v-model="model.name"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      required
                      type="email"
                      label="Email"
                      placeholder="name@naabsa.com"
                      input-classes="form-control-alternative"
                      :errors="errors.email"
                      v-model="model.email"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-select
                      alternative=""
                      label="Profile"
                      :errors="errors.role_id"
                      placeholder="Select the profile"
                      :options="Service.roles()"
                      @change="verificaRole()"
                      required
                      inputClasses="input"
                      input-classes="form-control-alternative"
                      v-model="model.role_id"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :required="model.id ? false : true"
                      type="password"
                      label="Password"
                      placeholder="********"
                      :errors="errors.password"
                      input-classes="form-control-alternative"
                      v-model="model.password"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :required="model.id ? false : true"
                      type="password"
                      label="Password Confirmation"
                      placeholder="********"
                      :errors="errors.password_confirmation"
                      input-classes="form-control-alternative"
                      v-model="model.password_confirmation"
                    />
                  </div>
                  <div
                    class="col-lg-4"
                    v-if="
                      model.role_id == 3 ||
                      model.role_id == 5 ||
                      model.role_id == 2
                    "
                  >
                    <multiple-select
                      multiple
                      :close-on-select="false"
                      :clear-on-select="false"
                      label="Port"
                      placeholder="Select the port"
                      :options="portos"
                      optionsValue="name"
                      v-model="model.porto_ids"
                      :errors="errors.porto_ids"
                    />
                  </div>
                  <div
                    class="col-lg-4"
                    v-if="model.role_id == 4 || model.role_id == 5"
                  >
                    <multiple-select
                      multiple
                      :close-on-select="false"
                      :clear-on-select="false"
                      label="Comm"
                      placeholder="Select the comm"
                      :options="cargas"
                      optionsValue="name"
                      v-model="model.carga_ids"
                      :errors="errors.carga_ids"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      label="Role"
                      placeholder="role"
                      inputClasses="input"
                      :errors="errors.role"
                      v-model="model.role"
                    />
                  </div>
                  <div class="col-lg-4">
                    <label for="photo fw-bold">Photo</label>
                    <div class="input-group mb-2">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input input"
                          id="photo"
                          accept="image/*"
                          @change="changePhoto"
                        />
                        <label
                          class="custom-file-label"
                          for="photo"
                          style="border-radius: 100px"
                          >Choose file</label
                        >
                      </div>
                    </div>
                    <!-- <base-input
                      type="file"
                      accept="image/*"
                      label="Photo"
                      placeholder="photo"
                      input-classes="form-control-alternative"
                      :errors="errors.photo"
                      v-model="model.photo"
                    /> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 btn-final txt-right pt-2">
        <!--     <multiple-select
          label="See Listing"
          style="
            width: 330px;
            display: inline-block;
            color: white;
            text-align: left;
          "
          :options="users"
          inputClasses="input"
          input-classes="form-control-alternative"
        /> -->
        <input
          type="submit"
          class="submit-branco"
          value="See listing"
          @click="$router.push('/usuarios/listar')"
        />

        <button
          type="submit"
          class="submit"
          value="Save"
          :disabled="loading"
          @click="submit()"
        >
          <i class="fa fa-spinner fa-spin" v-if="loading"></i>Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/services/Usuarios";
import PortosService from "@/services/Portos";
import CargaTipoService from "@/services/CargasTipos";
export default {
  name: "usuario_form",
  created() {
    PortosService.get({}).then((response) => {
      this.portos = response.data;
    });
    CargaTipoService.get({}).then(({ data }) => (this.cargas = data));
    if (this.$route.params.id) {
      this.model.id = this.$route.params.id;
      this.find();
    }
    Service.get({}).then(({ data }) => (this.users = data));
  },
  data() {
    return {
      errors: {},
      loading: false,
      portos: [],
      Service,
      cargas: [],
      model: {
        nome: "",
        email: "",
        password: "",
        photo: null,
        role: "",
        password_confirmation: "",
        role_id: null,
        porto_ids: [],
        carga_ids: [],
        id: null,
      },
      users: [],
    };
  },
  methods: {
    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, { with: ["portos", "cargas"] })
        .then(({ data }) => {
          this.model = data;
          this.model.porto_ids = data.portos.map((porto) => porto.id);
          this.model.carga_ids = data.cargas.map((carga) => carga.id);
          this.model.photo = null;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    verificaRole() {
      //Not is operator
      if (this.model.role_id != 3) this.model.porto_ids = [];
      //Not is client
      if (this.model.role_id != 4) this.model.carga_ids = [];
    },
    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.model.porto_ids = response.data.portos.map((porto) => porto.id);
          this.model.carga_ids = response.data.cargas.map((carga) => carga.id);
          Service.get({}).then(({ data }) => (this.users = data));
          this.loading = false;
          this.$notify({
            type: "success",
            message: "User created successfully!",
            horizontalAlign: "center",
          });
          this.$router.push("/usuarios/listar");
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
    },
    changePhoto(event) {
      this.model.photo = event.target.files[0];
    },
  },
};
</script>
<style scope>
.multiselect.input {
  background-color: white;
}
</style>
