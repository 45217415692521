<template>
  <div :class="{ main: !isModal, 'container-fluid': isModal }">
    <div class="row infos" v-if="!isModal">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-new.png" alt="icone barco" />
          <p v-html="model.id ? 'New line-up' : 'Edit line-up'"></p>
        </div>
      </div>
    </div>

    <div class="fundo" :class="{ ' card p-4 pt-0 card-body mb-2': isModal }">
      <div class="blc-inf-geral">
        <div class="blc-item" :class="{ ' pt-0 ': isModal }">
          <h2 v-if="!isModal">Line-ups</h2>

          <form @submit.prevent>
            <!-- porto -->
            <div class="row">
              <div class="col-12" v-if="!isModal">
                <h3>Information of port</h3>
              </div>

              <div class="col-lg-3 mrg-top-30">
                <base-select
                alternative=""
                label="Type"
                :errors="errors.is_observacao"
                :disabled="model.id"
                :options="Service.opcaoTipo()"
                required
                @change="setType(model.is_observacao)"
                v-model="model.is_observacao"
                />
              </div>
              <div class="col-lg-3 mrg-top-30">
                <base-select
                alternative=""
                label="Port"
                :errors="errors.porto_id"
                placeholder="select the port"
                :options="portos"
                required
                @change="setPorto(true)"
                v-model="model.porto_id"
                />
              </div>

              <div class="col-lg-3 mrg-top-30">
                <base-select
                alternative=""
                label="Terminal"
                :errors="errors.terminal_id"
                :disabled="!terminais.length"
                placeholder="select the terminal"
                :options="terminais"
                @change="setTerminal(true)"
                required
                v-model="model.terminal_id"
                />
              </div>

              <div class="col-lg-3 mrg-top-30">
                <base-select
                alternative=""
                label="Berth"
                :disabled="!bercos.length"
                :errors="errors.berco_id"
                :placeholder="
                terminal.bercos && !terminal.bercos.length
                ? 'This terminal does not have a cradle berth'
                : 'Select the berth'
                "
                :options="bercos"
                v-model="model.berco_id"
                />
              </div>
            </div>
            <!-- /porto -->

            <!-- carga -->
            <h3 v-if="!isModal">
              <span v-if="isLineup">Information of comm</span>
              <span v-else>Information of occurred</span>
            </h3>

            <div class="row">
              <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                <multiple-select
                alternative=""
                optionsValue="name_with_imo"
                label="Vessel"
                :disabled="!navios.length"
                :errors="errors.navio_id"
                addonLeftIcon=" fa fa-plus"
                inputClasses="input"
                placeholder="select the vessel"
                :options="navios"
                @btn="openModalCreate('navio')"
                v-model="model.navio_id"
                />
                <!-- <base-select
                      alternative=""
                      label="Vessel"
                      :disabled="!navios.length"
                      :errors="errors.navio_id"
                      placeholder="Select the vessel"
                      :options="navios"
                      addonLeftIcon=" fa fa-plus"
                      input-classes="form-control-alternative"
                      v-model="model.navio_id"
                      @btn="openModalCreate('navio')"
                      /> -->
                    </div>
                    <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                      <multiple-select
                      id="agency-select"
                      alternative=""
                      label="Agency"
                      inputClasses="input"
                      :disabled="!agencias.length"
                      :errors="errors.agencia_id"
                      placeholder="Select the agency"
                      :options="agencias"
                      addonLeftIcon=" fa fa-plus"
                      v-model="model.agencia_id"
                      ref="agencias"
                      @btn="openModalCreate('agencia')"
                      />
                    </div>
                    <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                      <base-select
                      alternative=""
                      label="Comm type"
                      :disabled="!cargasTipos.length"
                      :errors="errors.carga_tipo_id"
                      placeholder="select the comm type"
                      :options="cargasTipos"
                      @change="setCargaTipo(true)"
                      v-model="model.carga_tipo_id"
                      />
                    </div>
                    <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                      <multiple-select
                      alternative=""
                      label="Destination port"
                      :errors="errors.destination_porto"
                      placeholder="port of destination"
                      :options="lineupPortos"
                      optionsValue="port_name"
                      addonLeftIcon=" fa fa-plus"
                      inputClasses="input"
                      required
                      @btn="openModalCreate('lineup_porto', 'destination_porto')"
                      input-classes="form-control-alternative"
                      v-model="model.destination_porto"
                      />
                    </div>
                    <div class="col-lg-12 mrg-top-30" v-if="!isLineup">
                      <base-input
                      alternative=""
                      label="Observation"
                      type="text"
                      :errors="errors.observacao"
                      input-classes="form-control-alternative"
                      v-model="model.observacao"
                      />
                    </div>
                    <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                      <base-input
                      alternative=""
                      label="ETA"
                      type="date"
                      :errors="errors.eta"
                      input-classes="form-control-alternative"
                      v-model="model.eta"
                      />
                    </div>

                    <div class="col-lg-3 mrg-top-30">
                      <base-input
                      alternative=""
                      label="ETB"
                      type="date"
                      :errors="errors.etb"
                      input-classes="form-control-alternative"
                      v-model="model.etb"
                      />
                    </div>

                    <div class="col-lg-3 mrg-top-30">
                      <base-input
                      alternative=""
                      label="ETC"
                      type="date"
                      :errors="errors.etc"
                      input-classes="form-control-alternative"
                      v-model="model.etc"
                      />
                    </div>

              <!-- <div class="col-lg-3" v-if="isLineup">
                    <base-select
                      alternative=""
                      label="Unity"
                      :errors="errors.qty_unidade"
                      :options="Service.unidades()"
                      input-classes="form-control-alternative"
                      v-model="model.qty_unidade"
                      @change="setUnidade()"
                    />
                  </div> -->
                  <div class="col-lg-3 mrg-top-30" v-if="isLineup">
                    <base-select
                    alternative=""
                    label="Status"
                    :errors="errors.status"
                    placeholder="Select the status"
                    options-key="name"
                    :options="Service.status()"
                    required
                    v-model="model.status"
                    @change="setStatus"
                    />
                  </div>
                  <div class="col-lg-4" v-if="model.status == 'POB hh:mm lt'">
                    <base-input
                    alternative=""
                    label="POB (Pillot on Board) lt (local time)"
                    type="time"
                    :errors="errors.pob"
                    input-classes="form-control-alternative"
                    v-model="model.pob"
                    />
                  </div>

              <!-- <div class="col-lg-4" v-if="isLineup">
                    <base-select
                      alternative=""
                      label="Last port"
                      :errors="errors.import_porto"
                      placeholder="select the last port"
                      :options="lineupPortos"
                      optionsValue="port_name"
                      addonLeftIcon=" fa fa-plus"
                      required
                      @btn="openModalCreate('lineup_porto', 'import_porto')"
                      input-classes="form-control-alternative"
                      v-model="model.import_porto"
                    />
                  </div>

                  <div class="col-lg-4" v-if="isLineup">
                    <base-select
                      alternative=""
                      label="Next port"
                      :errors="errors.export_porto"
                      placeholder="select the next port"
                      :options="lineupPortos"
                      optionsValue="port_name"
                      addonLeftIcon=" fa fa-plus"
                      @btn="openModalCreate('lineup_porto', 'export_porto')"
                      required
                      input-classes="form-control-alternative"
                      v-model="model.export_porto"
                    />
                  </div> -->
                </div>
                <!-- /carga -->

                <!-- import/export -->
              </form>
            </div>
          </div>

          <div class="blc-inf-geral" v-if="isLineup">
            <div class="blc-item">
              <div class="row">
                <div class="col-md-6 col-12">
                  <h2>Comms</h2>
                </div>

                <div class="col-md-6 col-12 txt-right">
                  <button class="submit" @click="addCargaAfretador()">
                    Add Comm +
                  </button>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-lg-4 mrg-top-30" v-if="isLineup">
                  <multiple-select
                  label="Comm"
                  optionsValue="name_with_synonym"
                  :disabled="!cargas.length"
                  :errors="errors.carga_id"
                  addonLeftIcon=" fa fa-plus"
                  inputClasses="input"
                  placeholder="select the comm"
                  required
                  :options="cargas"
                  @change="setCarga()"
                  @btn="openModalCreate('carga')"
                  v-model="model.carga_id"
                  />
                </div>
                <div class="col-lg-4 mrg-top-30" v-if="isLineup">
                  <multiple-select
                  alternative=""
                  label="Charterer"
                  :errors="errors.afretador_id"
                  placeholder="Select the charterer"
                  :options="afretadores"
                  optionsValue="name"
                  addonLeftIcon=" fa fa-plus"
                  required
                  inputClasses="input"
                  v-model="model.afretador_id"
                  @btn="openModalCreate('afretador')"
                  />
                </div>
                <div class="col-lg-4 mrg-top-30" v-if="isLineup">
                  <div class="form-group has-label">
                    <label class="form-control-label">QTY</label>
                    <money class="input" v-model="model.qty" v-bind="qtd"></money>
                    <div
                    class="text-danger invalid-feedback"
                    style="display: block"
                    v-for="erro in errors.qty"
                    v-if="errors.qty"
                    >
                    {{ erro }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-for="(carga, index) in model.cargas_afretadores">
              <div class="col-lg-4 mrg-top-30">
                <multiple-select
                alternative=""
                label="Comm"
                optionsValue="name_with_synonym"
                :disabled="!cargas.length"
                :errors="errors['cargas.' + index + '.carga_id']"
                addonLeftIcon=" fa fa-plus"
                inputClasses="input"
                placeholder="select the comm"
                required
                :options="cargas"
                @btn="openModalCreate('carga')"
                input-classes="form-control-alternative"
                v-model="carga.carga_id"
                />
              </div>
              <div class="col-lg-4 mrg-top-30" v-if="isLineup">
                <multiple-select
                alternative=""
                label="Charterer"
                :errors="errors['cargas.' + index + '.afretador_id']"
                placeholder="Select the charterer"
                :options="afretadores"
                optionsValue="name"
                addonLeftIcon=" fa fa-plus"
                required
                inputClasses="input"
                v-model="carga.afretador_id"
                @btn="openModalCreate('afretador')"
                />
              </div>
              <div class="col-lg-3 mrg-top-30">
                <div class="form-group has-label">
                  <label class="form-control-label"> QTY </label>
                  <money class="input" v-model="carga.qty" v-bind="qtd"></money>
                  <div
                  class="text-danger invalid-feedback"
                  style="display: block"
                  v-for="erro in errors['cargas.' + index + '.qty']"
                  v-if="errors['cargas.' + index + '.qty']"
                  >
                  {{ erro }}
                </div>
              </div>
            </div>
            <div class="col-lg-1 mrg-top-30">
              <div class="form-group has-label">
                <label class="form-control-label" style="color: transparent">
                  button
                </label>
                <button
                class="btn base-button btn-danger redondo w-100"
                :disabled="loading"
                @click="() => model.cargas_afretadores.splice(index, 1)"
                >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal :show="modal.confirm.show">
    <template v-slot:header>Confirm</template>
    <p>Do you confirm that all information on the form is correct?</p>
    <button
    class="btn btn-sm btn-success"
    @click="modal.confirm.show = false"
    >
    Yes
  </button>
  <button
  class="btn btn-sm btn-danger"
  @click="
  modal.confirm.show = false;
  model.status = null;
  "
  >
  No
</button>
</modal>
<modal
:show="modal.create.show"
@close="
() => {
  modal.create.show = false;
}
"
>
<template v-slot:header> Add new </template>
<template>
  <generic-form
  v-if="modal.create.show"
  :service="modal.create.service"
  :type="modal.create.type"
  :params="modal.create.params"
  @success="handleModalForm"
  />
</template>
</modal>
<div class="row">
  <div class="col-12 btn-final txt-right">
    <input
    type="submit"
    class="submit-branco"
    value="See listing"
    @click="$router.push('/lineups/listar')"
    v-if="!isModal"
    />

    <button
    type="submit"
    class="submit"
    value="Save"
    :disabled="loading"
    @click="submit()"
    >
    <i class="fa fa-spinner fa-spin" v-if="loading" /> Save
  </button>
</div>
</div>
</div>
</template>
<script>
  import Service from "@/services/Lineups";
  import LineupsPortosService from "@/services/LineupsPortos";
  import PortosService from "@/services/Portos";
  import NaviosService from "@/services/Navios";
  import TrabalhadoresService from "@/services/Trabalhadores";
  import AgenciasService from "@/services/Agencias";
  import AfretadoresService from "@/services/Afretadores";
  import CargasTiposService from "@/services/CargasTipos";
  import CargasService from "@/services/Cargas";
  import GenericForm from "./../Generic/Form.vue";
  import { Money } from "v-money";
  export default {
    name: "lineups_form",
    components: {
      Money,
      "generic-form": GenericForm,
    },
    created() {
      this.modelDefault = { ...this.model };
      if (!this.withParams) {
        Service.parametros({
          portos: true,
          navios: true,
          agencias: true,
          lineups_portos: true,
          afretadores: true,
          trabalhadores: true,
          cargas: true,
        }).then((response) => {
          this.portos = response.data.portos;
          this.navios = response.data.navios;
          this.lineupPortos = response.data.lineups_portos;
          this.agencias = response.data.agencias;
          this.afretadores = response.data.afretadores;
          this.trabalhadores = response.data.trabalhadores;
          this.cargasTipos = response.data.cargas_tipos;
          if (this.$route.params.id) {
            this.model.id = this.$route.params.id;
            this.find();
          }
          if (this.id) {
          //prop
          this.model.id = this.id;
          this.find();
        }
        if (this.premodel.terminal_id) {
          this.model.porto_id = this.premodel.porto_id;
          this.model.terminal_id = this.premodel.terminal_id;
          this.setPorto(false);
          this.setTerminal(false);
        }
        if (this.premodel.call_in_id) {
          this.model.call_in_id = this.premodel.call_in_id;
        }
      });
      } else {
        this.navios = this.params.navios;
        this.agencias = this.params.agencias;
        this.cargasTipos = this.params.cargasTipos;

      // this.portos = this.params.portos;
      this.lineupPortos = this.params.lineupPortos;
      this.afretadores = this.params.afretadores;

      Service.parametros({ portos: true }).then(
        ({ data }) => {this.portos = data.portos;           
         if (this.$route.params.id) {
          this.model.id = this.$route.params.id;
          this.find();
        }
        if (this.id) {
        //prop
        this.model.id = this.id;
        this.find();
      }
      if (this.premodel.terminal_id) {
        this.model = {...this.premodel};
        // this.model.call_in_id = null;
        // this.model.terminal_id = this.premodel.terminal_id;
        this.setPorto(false);
        this.setTerminal(false);
        this.setCargaTipo(false);
      }
      if (this.premodel.call_in_id) {
        this.model.call_in_id = this.premodel.call_in_id;
      }
    }
    );


    }
  },
  props: {
    id: {
      default: null,
    },
    withParams: {
      default: false,
    },
    params: {
      default: () => {},
    },
    isModal: {
      default: 0,
    },
    premodel: {
      default: () => {},
    },
  },
  data() {
    return {
      qtd: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " (t)",
        precision: 3,
        masked: false,
      },
      modal: {
        create: {
          show: false,
          service: {},
          type: null,
          params: {},
          model: null,
          index: null,
        },
        confirm: {
          show: false,
        },
      },
      errors: {},
      portos: [],
      navios: [],
      afretadores: [],
      terminais: [],
      agencias: [],
      bercos: [],
      terminal: {},
      cargas: [],
      cargasTipos: [],
      lineupPortos: [],
      trabalhadores: [],
      loading: false,
      Service,
      modelDefault: {},
      model: {
        is_observacao: 0,
        porto: {},
        terminal: {},
        embarcadores: [],
        recebedores: [],
        afretador_id: null,
        agencia_id: null,
        navio_id: null,
        porto_id: null,
        terminal_id: null,
        berco_id: null,
        carga_tipo_id: null,
        carga: {},
        carga_id: null,
        export_porto: null,
        import_porto: null,
        destination_porto: null,
        status: "Loading",
        qty: 0,
        qty_unidade: "t",
        qty_import: 0,
        qty_export: 0,
        id: null,
        cargas_afretadores: [],
        call_in_id: null,
      },
    };
  },
  computed: {
    isLineup() {
      return this.model.is_observacao == 1 ? false : true;
    },
    saldoImport() {
      let total = 0;

      if (this.model.recebedores) {
        total += this.model.recebedores.reduce(
          (acumulador, elemento) => (acumulador += parseFloat(elemento.qty)),
          0
          );
      }
      this.model.qty_import = total;
      return total;
    },
    saldoExport() {
      let total = 0;

      if (this.model.embarcadores) {
        total += this.model.embarcadores.reduce(
          (acumulador, elemento) => (acumulador += parseFloat(elemento.qty)),
          0
          );
      }
      this.model.qty_export = total;
      return total;
    },
  },
  methods: {
    setStatus(evt) {
      if (evt.target.value != "Sailed") return;
      const selects = document.querySelectorAll("select");
      for (let e of selects) {
        const value = e.options[e.selectedIndex].text;
        if (value == "TBI") {
          this.$notify({
            type: "danger",
            message: "Please inform all fields",
            horizontalAlign: "center",
          });
          this.model.status = "";
          break;
        } else if (e.id == "agency-select" && value == "NAABSA") {
          this.modal.confirm.show = true;
        }
      }
      // document
      //   .querySelectorAll("select.form-control.form-control-alternative")
      //   .forEach((e) => {
      //     const value = e.querySelector(`option[value="${e.value}"]`).innerHTML;
      //     if (value == "TBI") {
      //       this.$notify({
      //         type: "danger",
      //         message: "Please inform all fields",
      //         horizontalAlign: "center",
      //       });
      //       this.model.status = "";
      //       evt.preventDefault();
      //     }
      //   });
    },
    setPorto(clean) {
      let porto = this.portos.find((port) => port.id == this.model.porto_id);

      if (porto) {
        this.terminais = [...porto.terminais];
        this.model.porto = porto;
      } else {
        this.model.porto = {};
        this.terminais = [];
        this.model.terminal_id = null;
        this.model.terminal = {};
      }
      if (clean) {
        this.model.terminal = {};
        this.model.terminal_id = null;

        this.bercos = [];
        this.model.berco = null;
        this.model.berco_id = null;
      }
      this.$forceUpdate();
    },
    setTerminal(clean) {
      let terminal = this.terminais.find(
        (term) => term.id == this.model.terminal_id
        );
      if (terminal) {
        this.terminal = terminal;
        this.bercos = [...terminal.bercos];
      } else {
        this.terminal = {};
        this.bercos = [];
        this.model.berco_id = null;
      }
      if (clean) {
        this.model.berco_id = null;
      }
    },

    setCargaTipo(clean) {
      let tipo = this.cargasTipos.find(
        (term) => term.id == this.model.carga_tipo_id
        );
      if (tipo) {
        // this.model.carga_tipo = tipo;
        this.cargas = [...tipo.cargas];
      } else {
        // this.model.carga_tipo = {};
        this.cargas = [];
        this.model.carga_id = null;
      }
      if (clean) {
        this.model.carga_id = null;
      }
    },
    addCargaAfretador() {
      this.model.cargas_afretadores.push({
        carga_id: null,
        qty: "",
        afretador_id: null,
      });
    },
    setCarga() {
      let carga = this.cargas.find((term) => term.id == this.model.carga_id);
      if (carga) {
        this.model.carga = carga;
      } else {
        this.model.carga = {};
      }
    },
    addTrabalhador(type) {
      if (type == 1) {
        //recebedor
        return this.model.recebedores.push({
          qty: 0,
          trabalhador_id: null,
          type: type,
        });
      }

      return this.model.embarcadores.push({
        qty: 0,
        trabalhador_id: null,
        type: type,
      });
    },
    openModalCreate(type, model, index) {
      switch (type) {
        case "agencia":
        this.modal.create.service = AgenciasService;
        this.modal.create.params = {};
        break;

        case "navio":
        this.modal.create.service = NaviosService;
        this.modal.create.params = {};
        break;

        case "trabalhador":
        this.modal.create.service = TrabalhadoresService;
        this.modal.create.params = {};
        this.modal.create.model = model;
        this.modal.create.index = index;
        break;

        case "afretador":
        this.modal.create.service = AfretadoresService;
        this.modal.create.params = {};

        break;
        case "lineup_porto":
        this.modal.create.service = LineupsPortosService;
        this.modal.create.params = {};
        this.modal.create.model = model;

        break;

        case "carga":
        if (!this.model.carga_tipo_id) {
          this.$notify({
            type: "danger",
            message: "Please select the comm type",
            horizontalAlign: "center",
          });
          return false;
        }
        this.modal.create.service = CargasService;
        this.modal.create.params = { tipo_id: this.model.carga_tipo_id };
        break;
      }

      this.modal.create.type = type;
      this.modal.create.show = true;
    },
    handleModalForm(model) {
      switch (this.modal.create.type) {
        case "agencia":
        this.model.agencia_id = model.id;
        this.agencias.push(model);
        break;
        case "navio":
        this.model.navio_id = model.id;
        this.navios.push(model);
        break;
        case "trabalhador":
        this.model[this.modal.create.model][this.modal.create.index][
        "trabalhador_id"
        ] = model.id;
        this.trabalhadores.push(model);
        break;
        case "lineup_porto":
        console.log(this.modal.create.model, model.id);
        this.model[this.modal.create.model] = model.id;
        this.lineupPortos.push(model);
        break;
        case "afretador":
        this.model.afretador_id = model.id;
        this.afretadores.push(model);
        break;
        case "carga":
        this.model.carga_id = model.id;
        this.cargas.push(model);
        break;
      }
      this.modal.create.type = null;
      this.modal.create.show = false;

      // this.$forceUpdate();
    },
    setImport() {
      this.model.ie_import = this.model.ie_import ? 0 : 1;
      if (!this.model.ie_import) {
        // this.model.import_porto = null;
        //todo
      }
      this.$forceUpdate();
    },
    setType(type) {
      this.$helper.set(
        this.model,
        { ...this.modelDefault, is_observacao: type },
        Object.keys(this.model)
        );
    },
    resetModel() {
      this.$helper.set(this.model, this.modelDefault, Object.keys(this.model));
    },
    setUnidade() {
      this.qtd.suffix = " (" + this.model.qty_unidade + ")";
    },
    setExport() {
      this.model.ie_export = this.model.ie_export ? 0 : 1;
      if (!this.model.ie_export) {
        // this.model.export_porto = null;
        //todo
      }
      this.$forceUpdate();
    },

    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, {
        with: ["carga", "embarcadores", "recebedores", "cargas_afretadores"],
      })
      .then((response) => {
        this.model = response.data;
        this.setPorto(false);
        this.setTerminal(false);
        this.setCargaTipo(false);
        this.setUnidade();
        this.loading = false;
        if (this.premodel.call_in_id) {
          this.model.id = null;
          this.model.call_in_id = this.premodel.call_in_id;
        }
      })
      .catch((err) => {
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
      .then((response) => {
        this.model = response.data;
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Lineup saved successfully!",
          horizontalAlign: "center",
        });
      })
      .catch((err) => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors;
          this.errors = errors;
          this.$notify({
            type: "danger",
            message: "Please check the form",
            horizontalAlign: "center",
          });
        }
      });
    },
  },
};
</script>
<style>
button.input-padding {
  padding: 0.625rem 0.75rem;
}
.transparent {
  color: transparent;
}
</style>
