// import "@/assets/vendor/nucleo/css/nucleo.css";
// import "@/assets/scss/argon.scss";
import "@/assets/css/bootstrap.min.css";


import "@/assets/css/style.css";
import "@/assets/css/custom.css";
// import "@/assets/js/jquery-3.7.0.min.js";
// import "@/assets/js/function.js";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";

import NotificationPlugin from "@/components/NotificationPlugin/index"

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);

    Vue.use(NotificationPlugin);

  }
};
