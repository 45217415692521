<template>
  <div class="main">
    <div class="row infos">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-grups.png" alt="icone barco">
          <p>Profile</p>
        </div>
      </div>
    </div>

    
      <div class="row">
        <div class="col-xl-6 order-xl-1">
          <div>
            <user-edit-card :user="user" />
          </div>
        </div>
        <div class="col-xl-6 order-xl-2">
          <user-password-card :user="user" />
        </div>
      </div>
    
  </div>
</template>
<script>
import UserEditCard from "@/views/Usuarios/UserProfile/UserEditCard.vue";
import UserPasswordCard from "@/views/Usuarios/UserProfile/UserPasswordCard.vue";
import UserCard from "@/views/Usuarios/UserProfile/UserCard.vue";

export default {
  layout: "DashboardLayout",

  components: {
    UserEditCard,
    UserPasswordCard,
    UserCard,
  },

  data() {
    return {
      user: {
        type: "profile",
        name: null,
        email: null,
        profile_image: null,
      },
    };
  },
  created() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
    },
  },
};
</script>
