<template>
  <div class="main">
    <div class="row infos">
    <div class="col-xl-4 col-lg-12">
      <div class="blc-dados">
        <img src="/img/icon-comm.png" alt="icone barco">
        <p>Email information</p>
      </div>
    </div>
  </div>
   <div class="fundo">
    <div class="blc-inf-geral">
      <div class="blc-item">
       
        <template>
          <form @submit.prevent="handleSubmit">
            <div class="col-lg-12">
              <div class="form-group has-label">
                <label class="form-control-label">
                  Email body
                  <i
                  class="fa fa-spinner fa-spin"
                  v-if="form.processing"
                  ></i>
                </label>
                <ckeditor
                :editor="editor"
                :config="editorConfig"
                v-model="form.description"
                ></ckeditor>
              </div>
            </div>
       


    <button type="submit" class="submit float-right" value="Save"    :disabled="form.processing"
    @click="submit()"><i class="fa fa-spinner fa-spin" v-if="form.processing" /> Save </button>
        </form>
      </template>
    </card>
  </div>
</div>
</div>
</div>
</template>
<script>
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Service from "@/services/Email";

  export default {
    data: () => ({
      editor: ClassicEditor,
      editorConfig: {
        heigth: 600,
      },
      form: {
        description: "",
        errors: {},
        processing: false,
      },
    }),
    mounted() {
      this.find();
    },
    methods: {
      async find() {
        this.form.processing = true;
        const { data } = await Service.get();
        this.form = { ...this.form, ...data };
        this.form.processing = false;
      },
      async handleSubmit() {
        this.form.processing = true;
        await Service.submit(this.form);
        this.form.processing = false;
      },
    },
  };
</script>
<style>
.ck-editor__editable_inline {
  min-height: 300px;
}
</style>
