<template>
  <div  class="main">
    <div class="row infos">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-comm.png" alt="icone barco">
          <p v-if="model.id">Edit Comm</p>
          <p v-else>New Comm</p>
        </div>
      </div>
    </div>


    <div class="fundo">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>Comm type</h2>
          <div class="row">
            <div class="col-xl-12 order-xl-1">
              <form @submit.prevent>
                <h3 >
                  Information of comm type
                </h3>

                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                    alternative=""
                    required
                    label="Name of comm type"
                    placeholder="name of comm type"
                    input-classes="form-control-alternative"
                    :errors="errors.name"
                    v-model="model.name"
                    />
                  </div>
                </div>

                <h3>
                  Comm of this type
                  <base-button
                  type="primary"
                  class="btn-side submit"
                  icon
                  size="sm"
                  @click="addCarga()"
                  >
                  <i class="fas fa-plus mr-2"></i>
                  <span class="btn-inner--text">Add comm</span>
                </base-button>
                <div
                class="text-danger invalid-feedback"
                style="display: block"
                v-for="erro in errors['cargas']"
                v-if="errors['cargas']"
                >
                {{ erro }}
              </div>
            </h3>

            <div class="row">
              <div class="col-md-12">
               
                    <div
                    class="form-row"
                    v-for="(carga, cargaIndex) in model.cargas"
                    >
                    <div class="col-lg-4">
                      <base-input
                      alternative=""
                      required
                      label="Comm"
                      placeholder="comm name"
                      input-classes="form-control-alternative"
                      :errors="errors['cargas.' + cargaIndex + '.name']"
                      v-model="carga.name"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                      alternative=""
                      required
                      label="Synonym"
                      placeholder="synonym"
                      input-classes="form-control-alternative"
                      :errors="
                      errors['cargas.' + cargaIndex + '.synonym']
                      "
                      v-model="carga.synonym"
                      />
                    </div>
                    <div class="col-lg-1">
                      <base-input
                      alternative=""
                     
                      label="Color"
                      placeholder="color"
                      input-classes="input color"
                      type="color"
                      :errors="
                      errors['cargas.' + cargaIndex + '.color']
                      "
                      v-model="carga.color"
                      />
                    </div>
                    <div class="col-lg-2">
                      <div class="has-label m-auto">
                        <label class="form-control-label">
                          To analyze
                        </label>
                        <br />
                        <label
                        class="custom-toggle mr-1 text-center"
                        >
                        <input
                        type="checkbox"
                        @change="
                        carga.ie_analise = carga.ie_analise ? 0 : 1
                        "
                        :checked="carga.ie_analise"
                        />
                        <span
                        data-label-off="Off"
                        data-label-on="On"
                        class="custom-toggle-slider rounded-circle"
                        ></span>
                      </label>
                      <div
                      class="text-danger invalid-feedback"
                      style="display: block"
                      v-for="erro in errors[
                      'cargas.' + cargaIndex + '.ie_analise'
                      ]"
                      v-if="
                      errors['cargas.' + cargaIndex + '.ie_analise']
                      "
                      >
                      {{ erro }}
                    </div>
                  </div>
                </div>
                          <!-- <div class="col-lg-1">
                            <div class="form-group has-label">
                              <label class="form-control-label"> Export </label>
                              <br />
                              <label class="custom-toggle mr-1 mt-2">
                                <input
                                  type="checkbox"
                                  @change="
                                    carga.ie_export = carga.ie_export ? 0 : 1
                                  "
                                  :checked="carga.ie_export"
                                />
                                <span
                                  data-label-off="Off"
                                  data-label-on="On"
                                  class="custom-toggle-slider rounded-circle"
                                ></span>
                              </label>
                              <div
                                class="text-danger invalid-feedback"
                                style="display: block"
                                v-for="erro in errors[
                                  'cargas.' + cargaIndex + '.ie_export'
                                ]"
                                v-if="
                                  errors['cargas.' + cargaIndex + '.ie_export']
                                "
                              >
                                {{ erro }}
                              </div>
                            </div>
                          </div> -->
                          <div class="col-lg-1">
                            <div class="form-group has-label">
                              <label
                              class="form-control-label"
                              style="color: transparent"
                              >
                              button
                            </label>
                            <button
                            type="submit"
                            class="btn base-button btn-danger w-100 redondo"
                            :disabled="loading"
                            @click="removeCarga(cargaIndex)"
                            >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                 
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 btn-final txt-right">
    <input type="submit" class="submit-branco" value="See listing" @click="$router.push('/cargas_tipos/listar')">

    <button type="submit" class="submit" value="Save"    :disabled="loading"
    @click="submit()"><i class="fa fa-spinner fa-spin" v-if="loading" /> Save </button>
  </div>
</div>

</div>
</template>
<script>
  import Service from "@/services/CargasTipos";
  export default {
    name: "usuario_form",
    created() {
      if (this.$route.params.id) {
        this.model.id = this.$route.params.id;
        this.find();
      }
    },
    data() {
      return {
        errors: {},
        loading: false,
        Service,
        model: {
          name: "",
          cargas: [],
          id: null,
        },
      };
    },
    methods: {
      addCarga() {
        this.model.cargas.unshift({
          id: null,
          name: "",
          tipo_id: this.model.id,
          ie_export: 0,
          ie_import: 0,
          ie_analise: 0,
          color: "#000000",
          bercos: [],
        });
      },
      removeCarga(index) {
        this.model.cargas.splice(index, 1);
        this.$notify({
          type: "info",
          message:
          "Comm removed. This change will only be effective after saving the comm type",
          horizontalAlign: "center",
        });
      },

      find() {
        this.loading = true;
        this.errors = {};
        Service.find(this.model.id, {
          with: "cargas",
        })
        .then((response) => {
          this.model = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      },
      submit() {
        this.loading = true;
        this.errors = {};
        Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Successfully saved comms!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
      },
    },
  };
</script>
<style></style>
